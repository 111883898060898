import React from 'react';
// import Header from './Component/main/Header';
import Layout from './Component/layout/Layout';
// import RightPanel from './Component/layout/RightPanel';


function App() {
  return (
    <div>
      <Layout />
    </div>
  )
};

export default App;